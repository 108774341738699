<!--  -->
<template>
  <div>
    <div v-if="language == 'zh'">
      <p class="common_text">团队介绍：HOTDIVE成立于2015年，我们是一支年轻的团队，团队核心成员都是一群热爱潜水的年轻人，我们拥有一支成熟的产品开发团队，团队中拥有优秀的软件工程师，硬件工程师和产品结构工程师。我们秉承让潜水更简单的产品设计理念，为客户提供高品质的潜水产品和优质的服务。我们专注潜水领域，不断创新，坚持用新技术和新理念为客户打造极致的潜水体验。</p>
    </div>
    <div v-else>
      <p class="common_text">hotdrive was founded in 2015. We are a most active team. The core members of the team consist of scuba enthusiasts. We have an innovative R&D team that include excellent software engineers, hardware engineers and structural engineers. We uphold the product design concept to make diving simple , and always to provide customers with high-quality diving products and services. We focus on the diving field, constantly innovate, and persist in creating the admirable diving products for customers with new technologies and new concepts.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: "",
    };
  },
  methods: {},
  created() {
    this.language = this.$route.query.language;
  },
  mounted() {},
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
  background-color: white;
}
.common_text{
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}
</style>