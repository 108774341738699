<template>
  <div id="app">
      <IndexM v-if="isMobile()"/>
      <Index v-else />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Index from "@/views/Index.vue";
import IndexM from "@/views/IndexM.vue";

export default {
  name: "app",
  components: {
    Index,
    IndexM
    // HelloWorld
  },
  methods: {
    //App.vue
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  /* margin-top: 60px; */
}
</style>
