<template>
  <div class="bg">
    <!-- <el-image class="bg_img" :src="bgimg" fit="cover"></el-image> -->
    <div class="info" :style="{backgroundImage:'url('+require('../assets/bg.jpeg')+')'}">
    <div class="empty"></div>
      <div>
        <div class="name">
          <el-image class="logo" :src="logo" fit="cover"></el-image>
          <p class="name_text">HOT DIVE</p>
        </div>
        <p class="text">一款能将手机秒变水下相机的神器。</p>
        <p class="text_en">
          A kind of amazing Smart Housing，instantly make phones become
          Underwater Camera.
        </p>
        <div class="download">
          <el-button
            class="dbtn"
            icon="el-icon-download"
            type="primary"
            @click="downAndrod"
            size
            round
          >
            Android版
          </el-button>
          <el-button
            class="dbtn"
            icon="el-icon-download"
            type="primary"
            @click="downIos"
            round
            >iOS版</el-button
          >
        </div>
        <!-- <div class="download_d_frame">
          <a class="download_d" href="/com.qianshui666.qianshuiapplication_1.1.1_11.apk">直接下载</a>
      </div> -->

        <div></div>

        <div class="contact">
          <p>使用问题、商务合作请联系</p>
          <p>Email: 254326370@qq.com</p>
          <p>电话:13575996910</p>
          <p>独角鲸科技（东莞）有限公司</p>
        </div>
      </div>
      <div class="empty"></div>
      <div class="footer">
          <p class="footer_text">
             独角鲸科技（东莞）有限公司
          <el-link href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022120032号</el-link>
          </p>
         
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      // bgimg: require(""),
      bgimg: require('../assets/bg.jpeg'),
      logo: require('../assets/hotdive_new.png'),
    }
  },
  methods: {
    downAndrod() {
      // this.$message({
      //   message: "敬请期待",
      //   center: true,
      //   duration: 1500,
      //   offset: 150
      // });
      window.location.href = 'http://www.hotdiveapp.com/hotdive.apk'
    },
    downIos() {
      window.location.href =
        'https://apps.apple.com/cn/app/hot-dive-%E6%89%8B%E6%9C%BA%E7%A7%92%E5%8F%98%E6%B0%B4%E4%B8%8B%E7%9B%B8%E6%9C%BA/id1511398380'
      // this.$message({
      //   message: "敬请期待",
      //   duration: 1500,
      //   center: true,
      //   offset: 150
      // });
    },
  },
}
</script>

<style>
/* .index {
  background-image: "url(" + require("../assets/bg.jpeg") + ")";
} */

/* .el-button–primary {
  color: #fff;
  background-color: #8b83d2;
  border-color: #8b83d2;
}
.el-button–primary:hover{
background-color: #0ABBF9;
border-color: #0ABBF9;
}
.el-button–primary:focus{
background-color: #0ABBF9;
border-color: #0ABBF9;
} */
html,
body,
#app {
  height: 100%;
  overscroll-behavior-y: none;
  /* background-color: royalblue; */
}

.note {
  display: flex;
  min-height: 100%;
  width: 100%;
}

.info {
  /* position: absolute;
  top: 20%;
  text-align: center;
  left: 50%;
  width: 80vw;
  margin-left: -40vw; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  flex: 1;
    /* height: 100%; */
}

.bg {
  width: 100%;
  height: 100%;
  /* display: flex; */
}

.bg_img {
  width: 100%;
  height: 100%;
}

.name {
  display: flex;
  justify-content: center;
  /* height: 50px; */
  text-align: center;
  align-items: center;
}

.name_text {
  /* line-height: 70px; */
  padding-left: 20px;
  font-size: 60px;
  margin: 0px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
}

.logo {
  width: 75px;
  height: 75px;
}

.text {
  /* line-height: 70px; */
  font-size: 30px;
  /* margin: 0px; */
  margin-left: 10px;
  margin-top: 50px;
  /* font-weight: bold; */
  color: white;
}
.text_en {
  /* line-height: 70px; */
  font-size: 25px;
  margin-left: 10px;
  /* margin: 0px; */
  /* font-weight: bold; */
  color: white;
}

.download {
  /* position: absolute; */
  /* bottom: 25%;
  right: 10%; */
  text-align: center;
  margin-top: 50px;
}
.dbtn {
  min-width: 140px;
}
.contact {
  padding-top: 100px;
  color: white;
}
.download_d_frame {
  padding: 10px;
}
.download_d {
  color: white;
}
.footer{
  /* height: 120px; */
  text-align: center;
}
.empty{
  flex: 1;
}
.footer_text {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #888888;
}
</style>