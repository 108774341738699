<!--  -->
<template>
  <div class="common_text">
    <div v-if="language=='zh'">
      <blockquote></blockquote>
      <div style="text-align: center">HOTDIVE用户隐私协议</div>
      <div style="text-align: left">
        <br />
        HOTDIVE尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，HOTDIVE会按照本隐私权政策的规定使用您的个人信息。但HOTDIVE将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，HOTDIVE不会将这些信息对外披露或向第三方提供。HOTDIVE会不时更新本隐私权政策。您在同意HOTDIVE服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于HOTDIVE服务使用协议不可分割的一部分。<br />
        <br />
        1.适用范围<br />
        <blockquote></blockquote>
        a)在您注册HOTDIVE帐号时，您根据HOTDIVE要求提供的个人注册信息；<br />
        b)在您使用HOTDIVE网络服务，或访问HOTDIVE平台网页时，HOTDIVE自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；<br />
        c)HOTDIVE通过合法途径从商业伙伴处取得的用户个人数据。您了解并同意，以下信息不适用本隐私权政策：<br />
        d)您在使用HOTDIVE平台提供的搜索服务时输入的关键字信息；<br />
        e)HOTDIVE收集到的您在HOTDIVE发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；<br />
        f)违反法律规定或违反HOTDIVE规则行为及HOTDIVE已对您采取的措施。<br />
        <br />
        2.信息使用<br />
        <blockquote></blockquote>
        a)HOTDIVE不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和HOTDIVE（含HOTDIVE关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。<br />
        b)HOTDIVE亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何HOTDIVE平台用户如从事上述活动，一经发现，HOTDIVE有权立即终止与该用户的服务协议。<br />
        c)为服务用户的目的，HOTDIVE可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与HOTDIVE合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。<br />
        <br />
        3.信息披露在如下情况下，HOTDIVE将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：<br />
        <blockquote></blockquote>
        a)经您事先同意，向第三方披露；<br />
        b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br />
        c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br />
        d)如您出现违反中国有关法律、法规或者HOTDIVE服务协议或相关规则的情况，需要向第三方披露；<br />
        e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br />
        f)在HOTDIVE平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，HOTDIVE有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。<br />
        g)其它HOTDIVE根据法律、法规或者网站政策认为合适的披露。<br />
        <br />
        4.信息存储和交换HOTDIVE收集的有关您的信息和资料将保存在HOTDIVE及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或HOTDIVE收集信息和资料所在地的境外并在境外被访问、存储和展示。<br />
        <blockquote></blockquote>
        <br />
        5.Cookie的使用<br />
        <blockquote></blockquote>
        a)在您未拒绝接受cookies的情况下，HOTDIVE会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的HOTDIVE平台服务或功能。HOTDIVE使用cookies可为您提供更加周到的个性化服务，包括推广服务。<br />
        b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的手工客网络服务或功能。<br />
        c)通过HOTDIVE所设cookies所取得的有关信息，将适用本政策。<br />
        <br />
        6.信息安全<br />
        <blockquote></blockquote>
        a)HOTDIVE帐号均有安全保护功能，请妥善保管您的用户名及密码信息。HOTDIVE将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。<br />
        <div style="text-align: left">
          b)在使用HOTDIVE网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是HOTDIVE用户名及密码发生泄露，请您立即联络HOTDIVE客服，以便HOTDIVE采取相应措施。
        </div>
        <p></p>
      </div>
    </div>
    <div v-else>
<blockquote></blockquote>
      <div style="text-align: center">Hotlive user privacy protocol</div>
      <div style="text-align: left">
        <br />
        Hotlive respects and protects the privacy of all users of the service. In order to provide you with more accurate and personalized services, hotlive will use your personal information in accordance with the provisions of this privacy policy. But hotdrive will treat this information with a high standard of diligence and prudence. Unless otherwise regulated in this privacy policy, hotlive will not disclose or provide such information to third parties without your prior permission. Hotlive will update this privacy policy from time to time. When you agree to the hotlive service use agreement, you shall be deemed to have agreed to all contents of this privacy policy. This privacy policy is an important part of the hotlive service agreement.<br />
        <br />
        1.Scope of application<br />
        <blockquote></blockquote>
        a) When you register your hotlive account, you have to provide the personal registration information according to hotlive's requirements;<br />
        b) When you use the hotdrive network service or visit web page on the hotdrive platform, hotdrive automatically receives and records the information on your browser and computer, including but not limited to your IP address, browser type, language used, visit date and time, software and hardware characteristic information, web page records you need, etc;<br />
        c) Hotdrive obtains user's personal data from business partners through legal channels. You understand and agree that the following information is not applicable to this Privacy Policy:<br />
        d) The keyword information of using the search service provided by hotlive platform;<br />
        e) Relevant information and data collected by hotdrive and released by you in hotdrive, including but not limited to the participative activities, transaction information and evaluation details;<br />
        f) Violations of the law or hotlive rules and the measures hotlive has taken against you.<br />
        <br />
        2.Information use<br />
        <blockquote></blockquote>
        a) Hotdrive will not provide selling, renting, sharing or dealing your personal information to any unrelated third party, unless it obtains your prior permission, or the third party and hotdrive (including hotdrive's affiliated companies) provide services for you individually or jointly, and after the end of the service, it will be prohibited from accessing all such materials including those previously accessible.<br />
        b) Hotlive also does not allow any third party to collect, edit, sell or freely disseminate your personal information by any means. If any hotdrive platform user is found to be engaged in the above activities, hotdrive has the right to terminate the service agreement with the user immediately.<br />
        c) For the purpose of serving users, hotdrive may provide you with information that you are interested in by using your personal information, including but not limited to sending you product and service information, or sharing information with hotdrive partners so that they can send you information about their products and services (the latter requires your prior consent).<br />
        <br />
        3.Information disclosure under the following circumstances, hotlive will disclose all or part of your personal information in accordance with your personal wishes or legal provisions:<br />
        <blockquote></blockquote>
        a)Disclosure to a third party with your prior consent;<br />
        b)In order to provide the products and services you require, you must share your personal information with a third party;<br />
        c)According to the relevant provisions of the law or the requirements of the administrative or judicial organs, disclose to the third party or the administrative or judicial organs;<br />
        d) If you violate the relevant laws and regulations of China or hotlive service agreement or relevant rules, you need to disclose to a third party;<br />
        e) If you are a qualified intellectual property complainant and have filed a complaint, you should disclose it to the complainee at the request of the complainee, so that both parties can handle the possible right dispute;<br />
        f) In a transaction created on hotdrive platform, if any party to the transaction has fulfilled or partially fulfilled the transaction obligations and made a request for information disclosure, hotdrive has the right to decide to provide the user with the necessary information such as the contact information of the other party to facilitate the completion of the transaction or the settlement of the dispute.<br />
        g) Other disclosure that hotlive considers appropriate in accordance with laws, regulations or website policies.<br />
        <br />
        4.Information storage and exchange the information and data about you collected by hotdrive will be stored on the servers of hotdrive and / or its affiliated companies, and these information and data may be transmitted to your country, region or overseas where hotdrive collects information and data, and accessed, stored and displayed overseas.<br />
        <blockquote></blockquote>
        <br />
        5.Use of cookies<br />
        <blockquote></blockquote>
        a) If you do not refuse to accept cookies, hotlive will set or access cookies on your computer so that you can log in or use hotlive platform services or functions that depend on cookies. Hotlive uses cookies to provide you with more thoughtful personalized services, including promotion services.<br />
        b) You have the right to accept or reject cookies. You can refuse to accept cookies by modifying your browser settings. However, if you choose to refuse to accept cookies, you may not be able to log in or use cookie dependent network services or functions.<br />
        c) This policy will apply to information obtained through hotlive cookies.<br />
        <br />
        6.Information security<br />
        <blockquote></blockquote>
        a) Hotlive account has security protection function. Please keep your user name and password information properly. Hotlive will ensure that your information will not be lost, abused or altered by encryption and other security measures. Despite there are above security measures, please also note that there is no "perfect security measures" on the information network.<br />
        <div style="text-align: left">
          b) When using hotlive network services for online transactions, it is inevitable for you to disclose your personal information, such as contact information or postal address, to the counterparties or potential counterparties. Please keep your personal information properly and provide it to others only when necessary. If you detect that your personal information is leaked, especially your hotlive user name and password, please contact hotlive customer service immediately so that hotlive can take corresponding measures.
        </div>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        language:""
    };
  },
  methods: {},
  created() {
      this.language = this.$route.query.language;
  },
  mounted() {},
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
  background-color: white;
}
.common_text{
  margin-left: 15px;
  margin-right: 15px;
}
</style>